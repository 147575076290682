<template>
  <div class="card">
    <div class="card-header border-0 pt-5">
<!--      <h3 class="card-title align-items-start flex-column">-->
<!--        <span class="card-label fw-bolder fs-3 mb-1">Action Log</span>-->
<!--      </h3>-->
      <div class="card-toolbar">
        <button class="btn btn-secondary" @click="fetchList">Filter</button>
      </div>
    </div>
    <div class="card-body py-3">

      <kt-datatable :current-page="datagrid.pagination.currentPage"
                    :loading="isLoading"
                    :rows-per-page="datagrid.pagination.itemsPerPage"
                    :total="changeRequestListTotalRecordsCount"
                    :table-data="changeRequestList"
                    :table-header="datagrid.columns"
                    :sort-column-name="datagrid.sort.columnName"
                    :sort-order="datagrid.sort.order"
                    v-on:current-change="onDatagridPaginationCurrentPageChange"
                    v-on:items-per-page-change="onDatagridPaginationItemsPerPageChange"
                    v-on:sort="onDatagridSortingChange"
      >
<!--
action: "fbcr.campaign.status.update"
chained: null
completed_at: "2021-10-29 13:33:41"
created_at: "2021-10-29 13:27:34"
deleted_at: null
facebook_async_batch_request_id: null
facebook_change_request_batch_id: null
facebook_request_id: null
failed_at: null
failed_job_uuid: null
id: 9
is_delayed: false
mode: "direct"
object_id: 1955110520991106
object_type: "facebook.campaign"
priority: null
queued_at: "2021-10-29 13:27:34"
scheduled_to: null
uuid: "ceef6967-d192-41ba-b008-d00a4f8b600d"
value: {status: "PAUSED"}
-->

        <template v-slot:cell-createdAt="{ row: data }">
          {{ formatDate(data.created_at) }}
        </template>
        <template v-slot:cell-completedAt="{ row: data }">
          {{ formatDate(data.completed_at) }}
        </template>
        <template v-slot:cell-action="{ row: data }">
          {{ data.action }}
        </template>
        <template v-slot:cell-objectType="{ row: data }">
          {{ data.object_type }}
        </template>
        <template v-slot:cell-objectId="{ row: data }">
          {{ data.object_id }}
        </template>
        <template v-slot:cell-value="{ row: data }">
          {{ JSON.stringify(data.value) }}
        </template>

      </kt-datatable>

    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, onMounted} from "vue";
import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import {mapActions, mapGetters} from "vuex";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc);
dayjs.extend(timezone);

export default defineComponent({
  name: "FacebookActionLogs",
  components: {
    KtDatatable,
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Facebook Change Requests");
    });
  },
  data() {
    return {
      datagrid: {
        columns: [
          {
            name: 'ID',
            key: 'id',
          },
          {
            name: 'Created At',
            columnKey: 'createdAt',
            key: 'created_at',
          },
          {
            name: 'Completed At',
            columnKey: 'completedAt',
            key: 'completed_at',
          },
          {
            name: 'Action',
            key: 'action',
          },
          {
            name: 'Object Type',
            columnKey: 'objectType',
            key: 'object_type',
          },
          {
            name: 'Object ID',
            columnKey: 'objectId',
            key: 'object_id',
          },
          {
            name: 'Value',
            key: 'value',
          },
        ],
        pagination: {
          itemsPerPage: 10,
          page: 1,
        },
        sort: {
          columnName: 'id',
          order: 'desc',
        }
      }

    };
  },

  computed: {
    ...mapGetters({
      isLoading: 'facebook/changeRequests/isFetching',
      list: 'facebook/changeRequests/list',
      changeRequestListTotalRecordsCount: 'facebook/changeRequests/listTotalRecordsCount',
    }),
  },

  mounted() {
    this.$nextTick(() => {
      this.fetchList();
    });
  },

  methods: {
    ...mapActions({
      fetchChangeRequests: 'facebook/changeRequests/fetch'
    }),

    formatDate(date) {
      if(!date) return null;
      return dayjs(date).tz(dayjs.tz.guess()).format('YYYY-MM-DD HH:mm');
    },

    fetchList() {
      this.fetchChangeRequests({
        limit: this.datagrid.pagination.itemsPerPage,
        page: this.datagrid.pagination.page,
        sort: this.datagrid.sort,
      });
    },

    onDatagridPaginationCurrentPageChange(currentPage) {
      this.datagrid.pagination.page = currentPage;
      this.fetchList();
    },
    onDatagridPaginationItemsPerPageChange(itemsPerPage) {
      this.datagrid.pagination.itemsPerPage = itemsPerPage;
      this.fetchList();
    },
    onDatagridSortingChange(sort) {
      this.datagrid.sort.order = sort.order;
      this.datagrid.sort.columnName = sort.columnName;
      this.fetchList();
    },

  },
});
</script>
