
import {defineComponent, onMounted} from "vue";
import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import {mapActions, mapGetters} from "vuex";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc);
dayjs.extend(timezone);

export default defineComponent({
  name: "FacebookActionLogs",
  components: {
    KtDatatable,
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Facebook Change Requests");
    });
  },
  data() {
    return {
      datagrid: {
        columns: [
          {
            name: 'ID',
            key: 'id',
          },
          {
            name: 'Created At',
            columnKey: 'createdAt',
            key: 'created_at',
          },
          {
            name: 'Completed At',
            columnKey: 'completedAt',
            key: 'completed_at',
          },
          {
            name: 'Action',
            key: 'action',
          },
          {
            name: 'Object Type',
            columnKey: 'objectType',
            key: 'object_type',
          },
          {
            name: 'Object ID',
            columnKey: 'objectId',
            key: 'object_id',
          },
          {
            name: 'Value',
            key: 'value',
          },
        ],
        pagination: {
          itemsPerPage: 10,
          page: 1,
        },
        sort: {
          columnName: 'id',
          order: 'desc',
        }
      }

    };
  },

  computed: {
    ...mapGetters({
      isLoading: 'facebook/changeRequests/isFetching',
      list: 'facebook/changeRequests/list',
      changeRequestListTotalRecordsCount: 'facebook/changeRequests/listTotalRecordsCount',
    }),
  },

  mounted() {
    this.$nextTick(() => {
      this.fetchList();
    });
  },

  methods: {
    ...mapActions({
      fetchChangeRequests: 'facebook/changeRequests/fetch'
    }),

    formatDate(date) {
      if(!date) return null;
      return dayjs(date).tz(dayjs.tz.guess()).format('YYYY-MM-DD HH:mm');
    },

    fetchList() {
      this.fetchChangeRequests({
        limit: this.datagrid.pagination.itemsPerPage,
        page: this.datagrid.pagination.page,
        sort: this.datagrid.sort,
      });
    },

    onDatagridPaginationCurrentPageChange(currentPage) {
      this.datagrid.pagination.page = currentPage;
      this.fetchList();
    },
    onDatagridPaginationItemsPerPageChange(itemsPerPage) {
      this.datagrid.pagination.itemsPerPage = itemsPerPage;
      this.fetchList();
    },
    onDatagridSortingChange(sort) {
      this.datagrid.sort.order = sort.order;
      this.datagrid.sort.columnName = sort.columnName;
      this.fetchList();
    },

  },
});
